// node_modules/transitions-all-settled/lib/set-stack.js
var SetStack = class {
  constructor() {
    this.map = /* @__PURE__ */ new Map();
  }
  has(key, item) {
    if (this.map.has(key)) {
      return this.map.get(key).has(item);
    }
    return false;
  }
  push(key, item) {
    if (this.map.has(key)) {
      this.map.get(key).add(item);
    } else {
      this.map.set(key, /* @__PURE__ */ new Set([item]));
    }
  }
  pop(key, item) {
    const set = this.map.get(key);
    if (!set)
      return;
    set.delete(item);
    if (set.size === 0)
      this.map.delete(key);
  }
  get empty() {
    return this.map.size === 0;
  }
};

// node_modules/transitions-all-settled/lib/signal.js
var Signal = class {
  constructor() {
    this.promise = new Promise((res, rej) => {
      this.resolve = res;
      this.reject = rej;
    });
  }
};

// node_modules/transitions-all-settled/index.js
function transitionsAllSettled(node) {
  let allEnded = true;
  const transitions = new SetStack();
  const signal = new Signal();
  function checkDone() {
    if (transitions.empty) {
      off();
      signal.resolve(allEnded);
    }
  }
  function onRun(e) {
    transitions.push(e.target, e.propertyName);
  }
  function onEnd(e) {
    if (transitions.has(e.target, e.propertyName)) {
      transitions.pop(e.target, e.propertyName);
      checkDone();
    }
  }
  function onCancel(e) {
    if (transitions.has(e.target, e.propertyName)) {
      allEnded = false;
      transitions.pop(e.target, e.propertyName);
      checkDone();
    }
  }
  function off() {
    node.removeEventListener("transitionrun", onRun);
    node.removeEventListener("transitionend", onEnd);
    node.removeEventListener("transitioncancel", onCancel);
  }
  node.addEventListener("transitionrun", onRun);
  node.addEventListener("transitionend", onEnd);
  node.addEventListener("transitioncancel", onCancel);
  return signal.promise;
}

// lib/classes.js
var DebutClasses = class {
  constructor(prefix) {
    this.prefix = prefix;
  }
  get beforeEnter() {
    return this.prefix + "--before-enter";
  }
  get enter() {
    return this.prefix + "--enter";
  }
  get afterEnter() {
    return this.prefix + "--after-enter";
  }
  get beforeExit() {
    return this.prefix + "--before-exit";
  }
  get exit() {
    return this.prefix + "--exit";
  }
  get afterExit() {
    return this.prefix + "--after-exit";
  }
  get all() {
    return [
      this.beforeEnter,
      this.enter,
      this.afterEnter,
      this.beforeExit,
      this.exit,
      this.afterExit
    ];
  }
};

// lib/element.js
var DebutElement = class {
  constructor(el, effect, group) {
    this.el = el;
    this.effect = effect;
    this.group = group;
    this.classes = new DebutClasses(this.effect);
  }
  set(name) {
    this.className(name);
  }
  reset() {
    this.el.classList.remove(...this.classes.all, this.effect);
  }
  async setOnEnd(name) {
    if (await transitionsAllSettled(this.el)) {
      this.set(name);
    }
  }
  get isEntering() {
    return this.el.classList.contains(this.classes.enter);
  }
  get isExiting() {
    return this.el.classList.contains(this.classes.exit);
  }
  className(name) {
    this.el.classList.remove(...this.classes.all);
    this.el.classList.add(this.classes[name], this.effect);
  }
};

// lib/utils.js
function nextAnimationFrame() {
  return new Promise((resolve) => requestAnimationFrame(resolve));
}
function includesWord(string, word) {
  return splitOnSpace(string).includes(word);
}
function splitOnSpace(string) {
  return string.trim().split(/\s+/);
}

// lib/group.js
var DebutGroup = class {
  constructor(elements) {
    this.elements = elements;
  }
  async enter() {
    if (this.isEntering)
      return;
    if (!this.isExiting)
      this.set("beforeEnter");
    await nextAnimationFrame();
    this.set("enter");
    return this.setOnEnd("afterEnter");
  }
  async exit() {
    if (this.isExiting)
      return;
    if (!this.isEntering)
      this.set("beforeExit");
    await nextAnimationFrame();
    this.set("exit");
    await this.setOnEnd("afterExit");
    this.reset();
  }
  set(state) {
    this.elements.map((m) => m.set(state));
  }
  reset() {
    this.elements.map((m) => m.reset());
  }
  setOnEnd(state) {
    return Promise.allSettled(this.elements.map((e) => e.setOnEnd(state)));
  }
  get isExiting() {
    return this.elements.some((e) => e.isExiting);
  }
  get isEntering() {
    return this.elements.some((e) => e.isEntering);
  }
};

// lib/target.js
function target(...targetArgs) {
  const elements = [];
  const targets = createTargets(targetArgs);
  for (const target2 of targets) {
    for (const effect of target2.effects) {
      elements.push(new DebutElement(target2.node, effect, target2.name));
    }
  }
  return new DebutGroup(elements);
}
function createTargets(targetArgs) {
  const result = [];
  for (const arg of targetArgs) {
    if (typeof arg === "string") {
      for (const node of queryForTarget(arg)) {
        if (includesWord(node.dataset.debutTarget, arg)) {
          result.push(new DebutTarget(node, arg));
        }
      }
    } else {
      result.push(new DebutTarget(arg));
    }
  }
  return result;
}
function queryForTarget(name) {
  return document.querySelectorAll(targetSelector(name));
}
function targetSelector(name) {
  return `[data-debut-target*="${name}"]`;
}
var DebutTarget = class {
  static fromString() {
  }
  constructor(node, name) {
    this.node = node;
    this.name = name;
    this.effects = splitOnSpace(node.dataset.debut);
  }
};

// lib/enter-exit.js
function enter(...targetArgs) {
  return target(...targetArgs).enter();
}
function exit(...targetArgs) {
  return target(...targetArgs).exit();
}
export {
  enter,
  exit,
  target
};
